
import { defineComponent, onMounted, computed, ref, watch } from "vue";
import { useForm, useField } from "vee-validate";
import * as yup from "yup";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { Actions, Modules } from "@/store/enums/AdminStoreEnums";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import SubmitButton from "@/components/SubmitButton.vue";

export default defineComponent({
  name: "AdminPortalEditModal",
  components: { SubmitButton },
  props: {
    portal: Object,
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    let isLoading = computed(() => {
      return store.getters[Modules.MASTER + "getIsLoading"];
    });

    let portal = ref(props.portal);

    const modalRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLButtonElement | null>(null);

    const schema = yup.object({
      name: yup.string().required().max(64).label("Portal Name"),
      code: yup.string().required().max(64).label("Portal Code"),
    });

    const { meta, errors, handleSubmit, isSubmitting, setErrors } = useForm({
      initialValues: {
        name: "",
        code: "",
      },
      validationSchema: schema,
    });

    const { value: name } = useField("name");
    const { value: code } = useField("code");

    const onSubmit = handleSubmit((values) => {
      return new Promise<void>((resolve) => {
        let payload = {
          id: portal.value?.id,
          values: values,
        };
        store
          .dispatch(Modules.MASTER + Actions.UPDATE_PORTAL, payload)
          .then(() => {
            Swal.fire({
              text: store.getters[Modules.MASTER + "getSuccessMessage"],
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              hideModal(modalRef.value);
              portal.value = {};
              name.value = "";
              code.value = "";
              store.dispatch(Modules.MASTER + Actions.FETCH_PORTALS, {
                search: "",
                url: "",
              });
            });
          })
          .catch(() => {
            setErrors(store.getters[Modules.MASTER + "getErrors"]);
            Swal.fire({
              text: "Failed to update the portal. Please fix the errors.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          })
          .finally(() => {
            resolve();
          });
      });
    });

    watch(
      () => props.portal,
      (newValue) => {
        portal.value = newValue;
        if (portal.value) {
          name.value = portal.value.name;
          code.value = portal.value.code;
        }
      }
    );

    return {
      isLoading,
      name,
      code,

      modalRef,
      submitButton,

      meta,
      errors,
      onSubmit,
      isSubmitting,
    };
  },
});
