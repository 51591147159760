
import { defineComponent, onMounted, computed, ref } from "vue";
import { useStore } from "vuex";
import { Actions, Modules } from "@/store/enums/AdminStoreEnums";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { formatDateTime } from "@/core/helpers/date";
import Pagination from "@/components/Pagination.vue";
import PortalCreateModal from "@/views/admin/master/PortalCreateModal.vue";
import PortalEditModal from "@/views/admin/master/PortalEditModal.vue";
import PortalEventLinkCreateModal from "@/views/admin/master/PortalEventLinkCreateModal.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "AdminPortals",
  components: {
    Pagination,
    PortalCreateModal,
    PortalEditModal,
    PortalEventLinkCreateModal,
  },
  setup() {
    const store = useStore();

    let search = ref("");
    let currentPageURL = ref("");

    let isLoading = computed(() => {
      return store.getters[Modules.MASTER + "getIsLoading"];
    });
    let portals = computed(() => {
      return store.getters[Modules.MASTER + "getPortals"];
    });
    let portalsMeta = computed(() => {
      return store.getters[Modules.MASTER + "getMeta"];
    });
    let portal = ref({});

    // let filteredEventEnvironments = computed(() =>
    //   Object.prototype.hasOwnProperty.call(eventEnvironments.value, "data")
    //     ? eventEnvironments.value.data.filter(
    //         (item) =>
    //           item.name.toUpperCase().includes(search.value.toUpperCase()) ||
    //           item.code.toUpperCase().includes(search.value.toUpperCase()) ||
    //           !search.value
    //       )
    //     : []
    // );

    onMounted(() => {
      setCurrentPageTitle("Portals");
    });

    fetchRecords();

    function refresh() {
      fetchRecords();
    }

    function searchNow() {
      currentPageURL.value = "";
      fetchRecords();
    }

    function gotoPage(url) {
      currentPageURL.value = url;
      fetchRecords();
    }

    function fetchRecords() {
      store.dispatch(Modules.MASTER + Actions.FETCH_PORTALS, {
        search: search.value,
        url: currentPageURL.value,
      });
    }

    function deleteRecord(id) {
      if (confirm("Are you sure do you want to delete it?")) {
        store
          .dispatch(Modules.MASTER + Actions.DELETE_PORTAL, id)
          .then(() => {
            Swal.fire({
              text: store.getters[Modules.MASTER + "getSuccessMessage"],
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              fetchRecords();
            });
          })
          .catch(() => {
            Swal.fire({
              text: "Failed to delete the portal. Please fix the errors.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
      }
    }

    function deleteEventLink(portalID, eventID) {
      if (confirm("Are you sure do you want to delete it?")) {
        let payload = {
          portalID: portalID,
          eventID: eventID,
        };
        store
          .dispatch(Modules.MASTER + Actions.DELETE_PORTAL_EVENT, payload)
          .then(() => {
            Swal.fire({
              text: store.getters[Modules.MASTER + "getSuccessMessage"],
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              fetchRecords();
            });
          })
          .catch(() => {
            Swal.fire({
              text: "Failed to delete the portal event. Please fix the errors.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
      }
    }

    return {
      formatDateTime,
      search,
      isLoading,
      portals,
      portalsMeta,
      portal,
      // filteredEventEnvironments,
      refresh,
      searchNow,
      gotoPage,
      deleteRecord,
      deleteEventLink,
    };
  },
});
