
import { defineComponent, onMounted, computed, ref, watch } from "vue";
import { useForm, useField } from "vee-validate";
import * as yup from "yup";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { Actions, Modules } from "@/store/enums/AdminStoreEnums";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import SubmitButton from "@/components/SubmitButton.vue";

export default defineComponent({
  name: "AdminPortalEventLinkCreateModal",
  components: { SubmitButton },
  props: {
    portal: Object,
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    let isLoading = computed(() => {
      return store.getters[Modules.MASTER + "getIsLoading"];
    });

    let portal = ref(props.portal);

    const modalRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLButtonElement | null>(null);

    const schema = yup.object({
      event_uid: yup.string().required().label("Event UID"),
    });

    const { meta, errors, handleSubmit, isSubmitting, setErrors } = useForm({
      initialValues: {
        event_uid: "",
      },
      validationSchema: schema,
    });

    const { value: event_uid } = useField("event_uid");

    const onSubmit = handleSubmit((values) => {
      let payload = {
        portalID: portal.value?.id,
        values: values,
      };
      return new Promise<void>((resolve) => {
        store
          .dispatch(Modules.MASTER + Actions.STORE_PORTAL_EVENT, payload)
          .then(() => {
            Swal.fire({
              text: store.getters[Modules.MASTER + "getSuccessMessage"],
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              hideModal(modalRef.value);
              store.dispatch(Modules.MASTER + Actions.FETCH_PORTALS, {
                search: "",
                url: "",
              });
            });
          })
          .catch(() => {
            setErrors(store.getters[Modules.MASTER + "getErrors"]);
            Swal.fire({
              text: "Failed to create the portal event. Please fix the errors.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          })
          .finally(() => {
            event_uid.value = "";
            resolve();
          });
      });
    });

    watch(
      () => props.portal,
      (newValue) => {
        portal.value = newValue;
        event_uid.value = "";
      }
    );

    return {
      isLoading,
      event_uid,

      modalRef,
      submitButton,

      meta,
      errors,
      onSubmit,
      isSubmitting,
    };
  },
});
